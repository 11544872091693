import { motion, useAnimation, useReducedMotion } from "framer-motion"
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"

function ValueProp({ tagline, title, subtitle, valuePropList, isMobile }) {
    // Validate inputs into function
    if ((!['string', 'object'].includes(typeof title))) throw new Error(`Expected 'title' prop to be one of the following ${JSON.stringify(['string', 'object'])} but received: ${typeof title}.`)
    if ((!['string', 'object'].includes(typeof subtitle))) throw new Error(`Expected 'subtitle' prop to be one of the following ${JSON.stringify(['string', 'object'])} but received: ${typeof subtitle}.`)
    if (typeof valuePropList !== 'object') throw new Error(`Expected 'valuePropList' prop to be a 'object' but received: ${typeof valuePropList}.`)

    const prefersReducedMotion = useReducedMotion()
    const control = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (isMobile || prefersReducedMotion) control.start("reducedMotion")
        else if (inView) control.start("animate");
        else control.start("initial");
    }, [control, inView, isMobile, prefersReducedMotion]);

    return (
        <div id="valuePropBox" className="w-full nexus-neutral-bg flex justify-center h-page-p v-page-p">
            <div className='flex flex-col w-full max-w-6xl'>
                <div>
                    {tagline && <small className="font-bold text-xs md:text-sm nexus-light-text uppercase select-none">{tagline}</small>}
                </div>
                <div id="valueProposition" className="py-4 md:pb-8 grid grid-rows-1 grid-cols-1 gap-y-6 lg:grid-rows-1 lg:grid-cols-2 lg:gap-x-20">
                    <div>
                        <h1 className="font-bold uppercase tracking-tight nexus-light-text text-2xl leading-normal select-none">{title}</h1>
                    </div>
                    <div>
                        <p className="mx-auto nexus-light-text text-start text-base select-none">{subtitle}</p>
                    </div>
                </div>
                <div className='flex items-start justify-center text-left p-4'>
                    <motion.div
                        ref={ref}
                        className='w-full grid justify-around grid-cols-1 gap-x-6 gap-y-6 max-w-4xl sm:grid-cols-2 lg:grid-cols-4 lg:max-w-6xl'
                    >
                        {
                            valuePropList.map((item, index) => {
                                
                                const fadeInRight = {
                                    initial: { x: '-20px', opacity: 0 },
                                    animate: { x: 0, opacity: 1, transition: { duration: 0.3, delay: 0.3 + Math.floor(index/4) * 0.3 } },
                                    reducedMotion: { x: 0, opacity: 1 }
                                }
                                return (
                                    <motion.div
                                        key={index}
                                        className="w-full flex flex-1 gap-x-3 items-center"
                                        variants={fadeInRight}
                                        initial="initial"
                                        animate={control}
                                    >
                                        <svg width="24" height="24" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path className="fill-[#0FA958]" d="M9 0.875C4.51992 0.875 0.875 4.51992 0.875 9C0.875 13.4801 4.51992 17.125 9 17.125C13.4801 17.125 17.125 13.4801 17.125 9C17.125 4.51992 13.4801 0.875 9 0.875ZM13.2285 6.27695L7.97852 12.527C7.92093 12.5955 7.84927 12.651 7.76839 12.6894C7.68752 12.7279 7.59932 12.7486 7.50977 12.75H7.49922C7.41162 12.75 7.325 12.7315 7.24499 12.6959C7.16497 12.6602 7.09335 12.6081 7.03477 12.543L4.78477 10.043C4.72762 9.98236 4.68317 9.91094 4.65403 9.83291C4.62488 9.75488 4.61163 9.67181 4.61504 9.58859C4.61846 9.50536 4.63848 9.42365 4.67392 9.34827C4.70936 9.27289 4.75952 9.20536 4.82143 9.14964C4.88335 9.09392 4.95578 9.05114 5.03447 9.02381C5.11315 8.99648 5.19651 8.98516 5.27963 8.99051C5.36276 8.99585 5.44398 9.01776 5.51851 9.05495C5.59305 9.09213 5.6594 9.14384 5.71367 9.20703L7.48281 11.1727L12.2715 5.47305C12.3789 5.34886 12.5309 5.27193 12.6945 5.25889C12.8582 5.24584 13.0205 5.29774 13.1462 5.40335C13.2719 5.50896 13.351 5.6598 13.3664 5.82327C13.3818 5.98675 13.3323 6.14971 13.2285 6.27695V6.27695Z" />
                                        </svg>
                                        <p className='flex flex-1 font-normal text-base nexus-light-text select-none'>{item.heading}</p>
                                    </motion.div>
                                )
                            })
                        }
                    </motion.div>
                </div>
            </div>
        </div>
    )
}

export default ValueProp