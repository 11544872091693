import React from "react"
import Storage from '../storage'


function Intro({ title, subtitle, btn, ctaId, ctaText }) {
  return (
    <div id='intro' className="relative w-full nexus-bg h-page-p v-page-p">
      <div className="flex flex-col items-center text-center gap-8">
        <div className="p-4 border-2 nexus-border rounded-full lg:animate-grow pointer-events-none">{Storage.global.logoInverted}</div>
        <h1 className="font-bold text-2xl nexus-text uppercase select-none">{title}</h1>
        {subtitle && <div className="select-none">{subtitle}</div>}
        {btn && <div>{btn}</div>}
        {ctaId && (
          <div className="grid gap-6 text-center">
            <p className="nexus-text uppercase select-none">{ctaText}</p>
            <div className="flex justify-center">
              <div
                role='button'
                tabIndex={0}
                className="group block w-10 h-10 md:animate-bounce cursor-pointer"
                // variants={fadeDown}
                initial="initial"
                // animate={control}
                onClick={() => { if (typeof document !== 'undefined') document.getElementById(ctaId).scrollIntoView({ behavior: 'smooth', block: 'center' }) }}
                onKeyUp={() => null}
                onKeyDown={(e) => { e.preventDefault(); if (e.key === 'Enter') if (typeof document !== 'undefined') document.getElementById(ctaId).scrollIntoView({ behavior: 'smooth', block: 'center' }) }}
              >
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512">
                  <path className="nexus-svg-inverted opacity-80 group-hover:opacity-100" d="M169.4 470.6c12.5 12.5 32.8 12.5 45.3 0l160-160c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L224 370.8 224 64c0-17.7-14.3-32-32-32s-32 14.3-32 32l0 306.7L54.6 265.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3l160 160z" />
                </svg>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  )
}

export default Intro