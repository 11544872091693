import { motion, useAnimation, useReducedMotion } from "framer-motion"
import React, { useEffect } from "react"
import { useInView } from "react-intersection-observer"

import Storage from '../storage'

function TwoColumnContent({ title, subtitle, tagline, btn, isMobile }) {
    const prefersReducedMotion = useReducedMotion()
    const control = useAnimation()
    const [ref, inView] = useInView()
    useEffect(() => {
        if (isMobile || prefersReducedMotion) control.start("reducedMotion")
        else if (inView) control.start("animate");
        else control.start("initial");
    }, [control, inView, isMobile, prefersReducedMotion]);

    return (
        <motion.div className="w-full nexus-neutral-bg flex justify-center h-page-p v-page-p" ref={ref}>
            <div className='w-full max-w-6xl grid lg:grid-cols-2 gap-y-4 lg:gap-x-10'>

                <div className="flex flex-col justify-between gap-6 lg:gap-4">
                    <small className="font-bold text-xs md:text-sm nexus-light-text uppercase select-none">{tagline}</small>
                    <h1 className="font-bold uppercase tracking-tight nexus-light-text text-2xl leading-normal select-none">{title}</h1>
                    <h2 className="nexus-light-text text-start text-base select-none">{subtitle}</h2>
                    <div className="hidden lg:block">
                        {btn}
                    </div>
                </div>

                <div className="flex flex-col justify-between gap-6 lg:gap-4">
                    {
                        Storage.pages.home.whyChooseUs.keyPoints.map((item, index) => {
                            const fadeInRight = {
                                initial: { x: '-20px', opacity: 0 },
                                animate: { x: 0, opacity: 1, transition: { duration: 0.3, delay: 0.3 + Math.floor(index / 1) * 0.3 } },
                                reducedMotion: { x: 0, opacity: 1 }
                            }
                            return (
                                <motion.div
                                    key={item.text}
                                    className="flex items-center gap-x-6"
                                    variants={fadeInRight}
                                    initial="initial"
                                    animate={control}
                                >
                                    <div className="w-14 h-14 p-4 lg:w-18 lg:h-18 lg:p-3 aspect-square rounded-full border border-white">
                                        {item.svg}
                                    </div>
                                    <div className="text-base nexus-light-text select-none">{item.text}</div>
                                </motion.div>
                            )
                        })
                    }
                </div>

                <div className="block lg:hidden">
                    {btn}
                </div>

            </div>
        </motion.div>
    )
}

export default TwoColumnContent
