import React from "react"
import ContactForm from "../lib/components/ContactForm"

import Intro from "../lib/components/Intro"
import Layout from "../lib/components/Layout"
import NexusButton from "../lib/components/NexusButton"
import PageTitlePanel from '../lib/components/PageTitlePanel'
import TwoColumnContent from '../lib/components/TwoColumnContent'
import ValueProp from '../lib/components/ValueProp'
import Storage from '../lib/storage'

function Page({ location }) {

  // React hook for checking if screen is mobile size
  const [width, setWidth] = React.useState(null);
  function handleWindowSizeChange() {
    setWidth(window.innerWidth);
  }
  React.useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener('resize', handleWindowSizeChange);
      return () => {
        window.removeEventListener('resize', handleWindowSizeChange);
      }
    }
    if (typeof window !== "undefined") {
      setWidth(window.innerWidth)
    }

  }, []);
  const isMobile = width <= 768;

  return (
    <Layout
      title={Storage.pages.about.metadata.title}
      description={Storage.pages.about.metadata.description}
      pageUrl={location.pathname}
      bgNavSelector="titlePanel"
    >
      <PageTitlePanel
        title={Storage.pages.about.metadata.title}
        subtitle={Storage.pages.about.metadata.description}
        backgroundImg={Storage.pages.about.titlePanel.backgroundImg}
        isMobile={isMobile}
        relativeUrl={location.pathname}
      />

      {/* TODO: About Us Information */}

      {/* TODO: Process/Approach */}

      <Intro
        title='About Nexus Commercial Services'
        subtitle={
          <div className="flex flex-col gap-6">
            <h1 className="text-base nexus-text">The origin of Nexus Commercial Services came from identifying the limited range of waterproofing products in Australia, and be easily accessible by Australian locals. We are not just believers in providing a broader range to the Australian market, we are serious about providing quality to preserve your next construction.</h1>
            <h2 className="text-base nexus-text">As specialists in providing waterproofing services, we are proud to provide quality waterproofing products suitable to cover for a range of waterproofing needs. Our product range is broad, with waterproofing products spanning across home foundation, roofs, floors, basements, bathrooms, balconies, and retaining walls.</h2>
            <h2 className="font-bold text-sm nexus-text">At Nexus Commercial Services, we tackle these problems by performing an in-depth, comprehensive assessment of the building environment. We combine our in-depth knowledge of waterproofing services with our high-quality sourcing of waterproofing products to minimise risks associated with water leaks and damages.</h2>
            <h3 className="text-base nexus-text font-bold uppercase tracking-wide">Think quality, think of Nexus Commercial Services.</h3>
          </div>
        }
        ctaId='aboutCta'
        ctaText="If you're as convinced about we are, reach out for more details."
      />

      <ValueProp
        tagline={Storage.global.valueProp.tagline}
        title={Storage.global.valueProp.title}
        subtitle={Storage.global.valueProp.subtitle}
        valuePropList={Storage.global.valueProp.valuePropList}
        isMobile={isMobile}
      />

      <TwoColumnContent
        title={`Your One-Stop Repairs and Waterproofing Provider`}
        subtitle={`Waterproofing is required to maintain structural integrity of building constructions against water-related damage. At Nexus Commercial Services, we strive to provide the highest quality services and products in order to protect your buildings from leaks and costly damages by offering a wide selection of both in order to cater to your needs. All the products that we sell are CNAS approved, a distinguished achievement that recognises the high quality of our waterproofing products.`}
        tagline='Why Choose Us'
        btn={<NexusButton title='Learn More About Nexus Commercial Services' link='/about-us' theme='accent' />}
        isMobile={isMobile}
      />

      <div>
        {/* Testimonial / Review section */}
      </div>

      {/* <Cta
        backgroundImg={Storage.pages.about.cta.img}
        title='Ready to get started?'
        btn={<NexusButton title='Enquire Now' link='/contact-us' linkType='internal' theme='dark' />}
      /> */}
      <div id='aboutCta' className="relative w-full h-page-p v-page-p nexus-bg">
        <div className="flex flex-col gap-4">
          <div className="grid gap-4">
            <h1 className="font-bold uppercase nexus-text">Contact Us</h1>
            <p className="font-normal nexus-text">Submit your enquiry below and we will reach out to you directly.</p>
          </div>
          <ContactForm />
        </div>
      </div>

    </Layout>
  )
}

export default Page
